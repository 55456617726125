import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['primaryLink', 'subMenu', 'subMenuToggle']
  get menuToggle() {
    return document.querySelector('.js-menu-toggle')
  }

  get skipToContainer() {
    return document.querySelector('.js-skipToContainer')
  }

  connect() {
    this.state = {
      hidden: true,
      isOpen: false,
      defaultBackground: '',
      backgroundImages: [],
      focusTrapInstance: null
    }

    this.element.style.display = 'none'
    this.menuToggle.addEventListener('click', this.toggleMenu.bind(this))
    this.menuToggle.addEventListener('keydown', this.handleKeydown.bind(this))
  }

  preloadMenuImages() {
    this.primaryLinkTargets.forEach((item, index) => {
      this.state.backgroundImages[index] = new Image;
      this.state.backgroundImages[index].src = item.dataset.bg
    })
  }

  swapBackground() {
    let backgroundImage = this.primaryLinkTarget.dataset.bg;

    if (!backgroundImage) {
      backgroundImage = this.state.defaultBackground;
    }

    this.element.style.backgroundImage = `url('${backgroundImage}')`;
  }

  handleKeydown(event) {
    if (event.keyCode == 13) {
      this.menuToggle.click()
    }
  }

  handleKeyUp(event) {
    if ((event.keyCode == 27) && this.state.isOpen) {
      this.menuToggle.click()
    }
  }

  toggleAriaHidden(elements) {
    elements.forEach((element) => {
      const ariaElement = document.querySelector(element)
      const ariaHidden = ariaElement.getAttribute('aria-hidden');
      const hidden = ariaElement.getAttribute('hidden')
      ariaElement.setAttribute('aria-hidden', !ariaHidden);
      ariaElement.removeAttribute('hidden', !hidden);
    })
  }

  toggleMenu(event) {
    this.preloadMenuImages()
    this.state.isOpen = !this.state.isOpen
    if (this.state.isOpen === true) {
      this.element.style.display = 'block'
      document.documentElement.classList.add('-hide-page-overflow')
      document.body.classList.add('-hide-page-overflow')
      document.body.classList.add('-is-open-mainmenu')
      setTimeout(()=> {
        this.element.classList.add('-animate')
        this.element.classList.add('is-open')
      }, 10)
      this.skipToContainer.style.display = 'none'

    } else {
      document.documentElement.classList.remove('-hide-page-overflow')
      document.body.classList.remove('-hide-page-overflow')
      document.body.classList.remove('-is-open-mainmenu')
      setTimeout(()=> {
        this.element.classList.remove('-animate')
        this.element.classList.remove('is-open')
      }, 10)
      setTimeout(()=>{
        this.element.style.display = 'none'
      }, 1000)
      this.skipToContainer.style.display = 'block'
    }

    this.element.setAttribute('aria-hidden', !this.state.hidden)
    this.toggleAriaHidden([
      "#content",
      '.header-bar .header-bar__holder > nav > .logo--link',
      '.header-bar .btn-book',
      '.header-bar .booking-bar',
      '.icon-burger',
    ])
  }

}
